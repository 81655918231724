.home-link-container {
    background-color: #f8f9fa;
    padding: 10px 0;
    text-align: center;
}

.home-link {
    color: #333;
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: none;
}

.home-link:hover {
    color: #007bff;
    text-decoration: none;
}

.navbar {
    background-color: #f8f9fa;
    padding: 10px 0;
}

.navbar-brand {
    color: #333;
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: none;
}

.navbar-brand:hover {
    color: #007bff;
    text-decoration: none;
}

.nav-link {
    color: #333;
    text-decoration: none;
}

.nav-link:hover {
    color: #007bff;
    text-decoration: none;
}

@media (max-width: 767px) {
    .navbar-nav {
        display: none;
    }
}

.go-back-link {
    display: inline-block;
    margin-bottom: 15px;
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.go-back-link:hover {
    text-decoration: underline;
}
